import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import { graphql } from 'gatsby'
import Footer from "@/components/footer";
import { Col, Container, Row } from "react-bootstrap";
import Ferrara05 from "@/images/ferrara/ferrara_05.jpg";
import imageUrlBuilder from '@sanity/image-url'
import clientConfig from '../../client-config'


export default function AttorneysPage ({ data }) {
  const attorneys = data.allSanityAttorney.nodes
  
  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <MenuContextProvider>
      <Layout PageTitle="Attorneys">
        <HeaderOne />
        <PageHeader title="Attorneys" image={Ferrara05} name="Attorneys" />
        <section className="team_member_area section_padding text-center section_border">
          <Container>
            <Row>
              {attorneys.map(attorneys => (
                attorneys.hideFromFront !== true &&
                <Col lg={3} md={6} sm={12} key={attorneys.id}>
                 <div className="team_member rounded-lg mb-3">
                    <a href={"/attorneys/"+ attorneys.slug.current}>
                      {/* <Img src={attorneys.image.asset.url} alt={attorneys.name} className="img-fluid rounded-lg" /> */}
                      <img src={urlFor(attorneys.image).width(300).height(200).url()} alt={attorneys.name} className="img-fluid rounded-lg"/>

                    </a>
                    <div className="team_details">
                    <a href={"/attorneys/"+ attorneys.slug.current}>
                      <h3 className="mb-0">
                        {attorneys.name}<br/> 
                      </h3>
                      <span className="font-light text-sm text-gray-500">{attorneys.title}</span>
                      </a>
                      <ul className="team_socials">
                          <li>
                            <a target="_blank" rel="noreferrer" href={attorneys.linkedIn}>
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                          <li>
                            <a href={'mailto:'+attorneys.email}>
                              <i className="fa fa-envelope"></i>
                            </a>
                          </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export const query = graphql`
  query allAttorneys {
    allSanityAttorney(
      sort: { fields: [order], order: ASC }
      ) {
      nodes {
        name
        title
        phone
        order
        linkedIn
        hideFromFront
        email
        id
        slug {
          current
        }
        image {
          asset {
            url
          }
        }
      }
    }
  }
`